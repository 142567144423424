const Home = () => {
    return (
        <div className={"min-vh-100 center-v width-container-home"} id={"homeSection"}>
            <div className={"m-4 section-margin"}>
                <div className={"home-header"}>Ever Barillas,</div>
                <div className={"home-header text-primary"}>Software Engineer</div>
                <div className={"text-secondary home-footer mt-2"}>Proud Penn State Alumnus</div>
            </div>
        </div>
    )
}

export default Home
