import profit from "../images/project_profit.png"
import weather from "../images/project_weather.png"
import game from "../images/project_2048.png"


const ProjectsList = () => {

    const projectItems = [
        {
            project: "Profit Lifter",
            type: "Android App",
            linkText: "Play Store",
            link: "https://play.google.com/store/apps/details?id=profitlifter.com",
            description: "A native Android application that estimates the profit that could be generate from selling products on e-commerce websites. Developed to provide users with useful insight into the viability of their business.",
            tech: ["Kotlin", "SQLite", "Android Studio"],
            image: profit
        },

        {
            project: "Weather Station",
            type: "Web App",
            linkText: "Repository",
            link: "https://github.com/everbarillas/Weather-Station",
            description: "A web application that displays the weather forecast of an area based on its zip-code.",
            tech: ["JavaScript", "Materialize CSS", "MapQuest API"],
            image: weather
        },

        {
            project: "2048",
            type: "Web App",
            linkText: "Repository",
            link: "https://github.com/everbarillas/2048",
            description: "A sliding block puzzle game that allows players to store their score.",
            tech: ["Vue.JS", "Cloud Firestore"],
            image: game
        }
    ]

    return (
        <div>
            {projectItems.map((projItem, projIndex) => (
                <div className={"border-t pt-4 mt-4"}  key={"proj-" + projIndex}>
                    <div className={"row"}>

                        {/* Project */}
                        <div className={"col-12 col-md-6 col-lg-5 d-flex align-content-center overflow-hidden"}>

                            {/* Image */}
                            <div className={"project-img-container center w-100"}><div className={"project-img"} style={{ backgroundImage: `url(${projItem.image})` }}/></div>

                            {/* Info */}
                            <div className={"center-v px-4 text-center text-md-start w-100"}>

                                {/* Title */}
                                <div className={"title"}>{projItem.project}</div>

                                {/* Project Type */}
                                <div className={"sub-title text-primary my-2"}>{projItem.type}</div>

                                {/* Link */}
                                <span><a href={projItem.link} target={"_blank"} rel={"noopener noreferrer"} className={"anchor_underline fw-500"}>{projItem.linkText}</a></span>

                            </div>
                        </div>

                        {/* Description & Tech */}
                        <div className={"col-12 col-md-6 col-lg-7 position-relative"}>

                            {/* Description */}
                            <div className={"mt-4 mt-md-0"}>{projItem.description}</div>

                            {/* Tech Bullet Points */}
                            <ul className={"tech-list m-0 mt-3 project-tech"}>
                                {projItem.tech.map((techItem, index) => {
                                    return <li key={"proj-tech-" + techItem + "-" + index}>{techItem}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
            <div className={"border-t mt-4"}/>
        </div>
    )
}

export default ProjectsList
