import AboutPicture from "./AboutPicture";

const About = () => {
    return (
        <div className={"center width-container"} id={"aboutSection"}>
            <div className={"m-4 section-bottom-margin w-100"}>

                {/* Section Title */}
                <div className={"section-title text-primary mb-4"}>About Me</div>

                <div className={"row"}>

                    {/* About Message */}
                    <div className={"col-12 col-md-7"}>

                        My name is Ever Barillas, a Software Engineer currently living in Harrisburg, PA.
                        I earned a Bachelor of Science in Computer Science degree from Pennsylvania State University in 2019.<br/><br/>

                        I see software engineering as a means to an end,
                        which is why I have no real preference when it comes to programming languages;
                        I always seek the best language for the job, but if I can't find it, I'll use Java.<br/><br/>


                        I like coding during my free time, and recently published on the Google Play store a native
                        Android Application that estimates profit from selling products on e&#8209;commerce websites.<br/><br/>

                        At my most recent job, I helped with the development of the new tolling services website for Pennsylvania's Turnpike,
                        working as lead front&#8209;end developer in charge of designing and developing the new user interface,
                        and as a back&#8209;end developer, helping rebuild the legacy infrastructure of the original site.<br/><br/>

                        These are languages I'm familiar with:
                        <div className={"row text-start"}>
                            <div className={"col-6 mt-2"}><span className={"text-primary"}>&#x2609;</span> Java (3 years)</div>
                            <div className={"col-6 mt-2"}><span className={"text-primary"}>&#x2609;</span> C# (2 years)</div>
                            <div className={"col-6 mt-2"}><span className={"text-primary"}>&#x2609;</span> Kotlin (2 years)</div>
                            <div className={"col-6 mt-2"}><span className={"text-primary"}>&#x2609;</span> Swift (1 year)</div>
                            <div className={"col-12 mt-2"}><span className={"text-primary"}>&#x2609;</span> JavaScript (2 years)</div>
                        </div>
                    </div>

                    {/* 3D Picture */}
                    <div className={"col-12 col-md-5 mt-5 mt-md-0 center about-perspective"}>
                       <AboutPicture/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
