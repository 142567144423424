import ExperienceTimeline from "./ExperienceTimeline";

const Experience = () => {
    return (
        <div className={"center width-container"} id={"experienceSection"}>
            <div className={"m-4 section-bottom-margin w-100"}>

                {/* Section Title */}
                <div className={"section-title text-primary mb-4 pb-2"}>Experience</div>
                <ExperienceTimeline/>
            </div>
        </div>
    )
}

export default Experience
