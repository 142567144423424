import email from "../images/icon_email.svg";
import linked from "../images/icon_linkedin.svg";
import github from "../images/icon_github.svg";
import phone from "../images/icon_phone.svg";
import at from "../images/icon_at.svg";

const Contact = () => {

    const contactItems = [
        {
            message: "Send me an email",
            linkText: "everbarillas@outlook.com",
            link: "mailto:everbarillas@outlook.com",
            icon: email
        },

        {
            message: "Add me on LinkedIn",
            linkText: "everbarillas",
            link: "https://www.linkedin.com/in/everbarillas/",
            icon: linked
        },

        {
            message: "Follow me on GitHub",
            linkText: "everbarillas",
            link: "https://github.com/everbarillas",
            icon: github
        },

        {
            message: "Call me (or text me)",
            linkText: "717-331-3661",
            link: "tel:717-331-3661",
            icon: phone
        }
    ]

    return (
        <div className={"center width-container"} id={"contactSection"}>
            <div className={"m-4 section-bottom-margin w-100"}>

                {/* Section Title */}
                <div className={"section-title text-primary"}>Contact Me</div>

                <div className={"row contact-perspective"}>

                    {contactItems.map((contactItem, contactIndex) => (
                        <div className={"col-12 col-md-6 mt-4 position-relative"}  key={"contact-" + contactIndex}>

                            {/* Card */}
                            <a href={contactItem.link} target={"_blank"} rel={"noopener noreferrer"} >
                                <div className={"contact-card center-v text-center"}>

                                    {/* Inner background */}
                                    <div className={"contact-card-bg"} style={{ backgroundImage: `url(${contactItem.icon})`}}/>

                                    {/* Content */}
                                    <div className={"contact-card-content"}>

                                        {/* Icon */}
                                        <img className={"contact-img w-100"} src={contactItem.icon} alt={contactItem.message}/>

                                        {/* Message */}
                                        <div className={"title text-neutral mt-3 mb-2"}>{contactItem.message}</div>

                                        {/* Contact */}
                                        <div className={"text-primary sub-title fw-500"}><img className={"contact-at me-1"} src={at} alt={"at"}/>{contactItem.linkText}</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default Contact
