import resume from "../files/Resume Ever Barillas.pdf"
import icon from "../images/icon_resume.svg";

const NavBar = () => {

    return (
        <a href={resume} rel="noopener noreferrer" target="_blank">
            <div className={"resume-container mb-4 me-4"}>
                <div className="resume-fab">
                    <img className={"resume-image"} src={icon} alt={"Resume"}/>
                    <div>Resume</div>
                </div>
            </div>
        </a>
    )
}

export default NavBar
