import NavBar from "./sections/NavBar";
import Home from "./sections/Home";
import About from "./sections/About";
import Experience from "./sections/Experience";
import Projects from "./sections/Projects";
import Contact from "./sections/Contact";
import ResumeButton from "./sections/ResumeButton";

function App() {
  return (
      <div>
          <NavBar/>
          <Home/>
          <About/>
          <Experience/>
          <Projects/>
          <Contact/>
          <ResumeButton/>
      </div>
  );
}


export default App;
