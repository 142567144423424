import React, {Component} from "react";

class AboutPicture extends Component {
    render() {
        return (
            <div className={"about-back center"} id={"aboutPic"}>
                <div className={"about-pic"}/>
            </div>
        )
    }
}

document.addEventListener('mousemove', (e) => {

    let picture = document.getElementById("aboutPic");

    let xAxis = (window.innerWidth / 2 - e.pageX) / 150;
    let yAxis = (window.innerHeight / 2 - e.pageY) / 150;

    picture.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
});

export default AboutPicture;
