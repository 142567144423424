import ProjectsList from "./ProjectsList";

const Projects = () => {
    return (
        <div className={"center width-container"} id={"projectsSection"}>
            <div className={"m-4 section-bottom-margin w-100"}>

                {/* Section Title */}
                <div className={"section-title text-primary"}>Projects</div>
                <ProjectsList/>
            </div>
        </div>
    )
}

export default Projects
