const ExperienceTimeline = () => {

    const experienceItems = [
        {
            job: "TransCore",
            title: "Software Engineer",
            dates: "July 2019 - July 2021",
            tech: ["C#", "JavaScript", "JQuery", "HTML", "CSS", ".NET Framework", "ASP.NET", "Razor", "Bootstrap"],
            bullets: [
                "Designed and developed the new user interface of Pennsylvania’s Turnpike web tolling services.",
                "Rebuilt tolling services’ legacy infrastructure, including payment handling and user transaction searches.",
                "Developed internal data analysis web application to provide real-time insights into revenue generated and potential growth.",
                "Built a survey application that allows customer service representatives gather feedback from customers to generate service satisfaction reports.",
                "Designed email templates and developed a markup visualization tool to ensure design integrity across multiple email providers.",
                "Worked on multiple projects in an Agile environment utilizing a test-driven development approach."
            ]
        },

        {
            job: "Penn State Health Milton S. Hershey Medical Center – Dr. Adrian D. Zurca",
            title: "IOS Developer – Capstone Project",
            dates: "January 2019 – May 2019",
            tech: ["Swift", "Apple ResearchKit SDK", "Apple CareKit SDK", "XCode"],
            bullets: [
                "Developed native iOS application to train Pediatric Intensive Care Unit resident physicians at Penn State Health Children’s Hospital.",
                "Involved in all phases of the project including design, development, testing, debugging, and deployment of the application.",
                "Collaborated directly with Hershey Medical Center representatives in defining application requirements.",
                "Created the application’s UX wireframes and interaction design.",
                "Develop statistical analysis models based on data gathered to help supervising faculty improve resident physicians’ learning experience.",
                "Worked on enabling accessibility across multiple iOS devices including different iPhone and iPad models"
            ]
        },

        {
            job: "Undergraduate Independent Study – Dr. Linda Null",
            title: "Android Developer",
            dates: "January 2019 – May 2019",
            tech: ["Java", "SQLite", "Google Maps API", "Android Studio"],
            bullets: [
                "Developed native Android application that maps service coverage of electric utility companies in Pennsylvania to determine powerline ownership.",
                "Implemented Material Design guidelines in the creation of the user interface.",
                "Integrated feature that allows users to plot polygon data on integrated Google Maps.",
                "Worked on map boundary visualization and detection based on polygon data collected and device’s GPS coordinates.",
                "Performed tests on android emulator and physical devices, across multiple Android API levels, to ensure application compatibility."
            ]
        }
    ]

    return (
        <div>
            {experienceItems.map((expItem, expIndex) => (
                <div className={"experience-container"} key={"exp-" + expIndex}>

                    {/* Vertical Line */}
                    <div className={"experience-line"}/>

                    {/* Experience Info */}
                    <div className={"text-start"}>
                        <div className={"title ms-2 ps-4 pb-2"}>{expItem.job}</div>
                        <div className={"sub-title text-primary ms-2 ps-4 pb-2"}>{expItem.title}</div>
                        <div className={"experience-date ms-2 ps-4 pb-2"}>{expItem.dates}</div>

                        {/* Tech Bullet Points */}
                        <ul className={"tech-list ms-2 ps-4 pb-2"}>
                            {expItem.tech.map((techItem, index) => {
                                return <li key={"exp-tech-" + expIndex + "-" + index}>{techItem}</li>;
                            })}
                        </ul>

                        {/* Examples Bullet Points */}
                        {expItem.bullets.map((bullet, index) => {
                            return <div className={"experience-bullet ms-2 ps-4"} key={"exp-bullet-" + expIndex + "-" + index}>{bullet}</div>;
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ExperienceTimeline
