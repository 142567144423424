import {Link} from 'react-scroll'

const NavBar = () => {

    const navItems = [
        {
            section: "About Me",
            scroll: "aboutSection"
        },

        {
            section: "Experience",
            scroll: "experienceSection"
        },

        {
            section: "Projects",
            scroll: "projectsSection"
        },

        {
            section: "Contact Me",
            scroll: "contactSection"
        },
    ]

    return (
        <div className="navigation justify-content-center justify-content-lg-between">

            {/* Logo */}
            <Link className={"nav-logo d-none d-lg-block"} href={"homeSection"} to={"homeSection"} spy={true} hashSpy={true} smooth={true} duration={150} delay={20} isDynamic={true}>
                <svg  viewBox={"0 0 23 23"} fill={"none"} stroke={"rgb(231, 29, 54)"} strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"1"}>
                    <g>
                        <g>
                            <polygon className="cls-1" points="11.5 0.5 0.5 6.05 11.5 11.5 22.5 6.05 11.5 0.5"/>
                            <polyline className="cls-1" points="0.5 17.05 11.5 22.5 22.5 17.05"/>
                            <polyline className="cls-1" points="0.5 11.5 11.5 17.05 22.5 11.5"/>
                            <polyline className="cls-1" points="5.85 8.82 0.5 11.5 11.5 17.05"/>
                            <polyline className="cls-1" points="11.5 17.05 22.5 11.5 17.13 8.77"/>
                            <polyline className="cls-1" points="5.79 14.4 0.5 17.05 11.5 22.5"/>
                            <polyline className="cls-1" points="11.5 22.5 22.5 17.05 17.23 14.37"/>
                            <line className="cls-1" x1="0.5" y1="17.05" x2="0.5" y2="6.05"/>
                        </g>
                    </g>
                </svg>
            </Link>


            <ul className={"nav-menu"}>
                {navItems.map((item, index) => {
                    return (
                        <li key={"nav-" + index}>
                            <Link className={"color-primary"} href={item.scroll} to={item.scroll}  spy={true} hashSpy={true} smooth={true} duration={150} delay={20} isDynamic={true}>
                                <span>{item.section}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default NavBar
